function boom() {
	const wave = document.querySelector('.bowe-audio');
	const playButton = wave.querySelector('.play-button');
	const toggle = wave.querySelector('.bowe-audio-toggle input');

	const waveSurferConfig = {
		waveColor: '#DBDBDB',
		progressColor: 'transparent',
		cursorColor: 'transparent',
		autoplay: false,
		height: 105,
		barHeight: 2,
		barMinHeight: 1,
		interact: false,
		barWidth: 3,
		barRadius: 20,
		barGap: 3,
		backend: 'MediaElement',
	};

	const waveSurferGood = WaveSurfer.create({
		...waveSurferConfig,
		container: wave.querySelector('.wavesurfer.good'),
		url: wave.querySelector('.wavesurfer.good').getAttribute('data-audio'),
	});

	const waveSurferBad = WaveSurfer.create({
		...waveSurferConfig,
		container: wave.querySelector('.wavesurfer.bad'),
		url: wave.querySelector('.wavesurfer.bad').getAttribute('data-audio'),
		waveColor: '#868686',
		barHeight: 4,
		barMinHeight: 2,
	});

	playButton.addEventListener('click', togglePlay);
	toggle.addEventListener('change', playWaves);

	function togglePlay() {
		console.log('Play button clicked');

		wave.classList.toggle('is-playing');
		playButton.classList.toggle('playing');
		playWaves();
	}

	function playWaves() {
		console.log(
			'playWaves function called, is-playing class present:',
			wave.classList.contains('is-playing')
		);

		if (!wave.classList.contains('is-playing')) {
			waveSurferBad.pause();
			waveSurferGood.pause();
			return;
		}

		waveSurferBad.play();
		waveSurferGood.play();

		if (toggle.checked) {
			setWaveSurferOptions(waveSurferBad, true, 'transparent');
			setWaveSurferOptions(waveSurferGood, false, '#0E2F4F');
		} else {
			setWaveSurferOptions(waveSurferBad, false, '#0E2F4F');
			setWaveSurferOptions(waveSurferGood, true, 'transparent');
		}
	}

	function setWaveSurferOptions(waveSurfer, volume, progressColor) {
		//waveSurfer.setVolume(volume);
		waveSurfer.setMuted(volume);
		waveSurfer.setOptions({ progressColor });
	}
}

document.addEventListener('DOMContentLoaded', boom);
